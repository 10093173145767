$('.owl-carousel').owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    responsive:{
        0:{
            items:1
        },
        376:{
            items:2
        },
        600:{
            items:3
        },
        1000:{
            items:4
        },
        1200:{
            items:5
        }
    }
})
$('.hamburger').click(function() {
    $('nav ul').toggle("slow");
});

function ThisIsWebP() {
    var def = $.Deferred(), crimg = new Image();
    crimg.onload = function() { def.resolve(); };
    crimg.onerror = function() {def.reject(); };
    crimg.src = "https://simpl.info/webp/cherry.webp";
    return def.promise();
}

ThisIsWebP().then(function() {
    $('.webp').addClass('webp');
}, function() {
    $('.webp').removeClass().addClass('no-webp');
});